<template>
    <section class="section hero is-fullheight is-error-section">
      <div>
        <!-- <div class="container"> -->
          <div class="columns is-centered">
            <div class="column is-two-fifths">
              <div class="card has-card-header-background">
                <header class="card-header">
                  <p class="card-header-title">
                    <span class="icon">
                      <i class="mdi mdi-lock default"></i>
                    </span>
                      <span>SSIPLENGG - Admin Login</span>
                  </p>
                  <!-- <a href="/dashboard" class="button is-small router-link-active">
                    Dashboard
                  </a> -->
                </header>
                <div class="card-content">
                  <login-component />
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->
      </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex'
import LoginComponent from '@/components/LoginComponent'

export default {
  name: 'Login',
  components: {
    LoginComponent
  },
  created () {
    this.logout()
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>

<template>
    <form @submit.prevent="onSubmit">
      <b-field
        label="Email"
        :type="validateState('email')"
        :message="{
            'This field is required & must be an email.': $v.form['email'].$anyError
        }">
        <b-input
          icon="email"
          placeholder="Your email here"
          v-model="$v.form.email.$model"
          name="email"
          type="email"
          autocomplete="new-email"
        />
      </b-field>
      <b-field
        label="Password"
        :type="validateState('password')"
        :message="{
            'This field is required & must be atleast 6 characters.': $v.form['password'].$anyError
        }"
      >
        <b-input
          icon="lock"
          placeholder="Your password here"
          v-model="$v.form.password.$model"
          name="password_confirmation"
          type="password"
          required
          autocomplete="new-password"
        />
      </b-field>
      <hr />
      <b-field grouped>
        <div class="control">
          <button
            type="submit"
            class="button is-black"
            :class="{ 'is-loading': status.loggingIn }"
          >
            Login
          </button>
        </div>
        <div class="control">
          <a
            href="/password-recovery"
            class="button is-outlined is-black"
          >
            Forgot Password?
          </a>
        </div>
      </b-field>
    </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'LoginComponent',
  mixins: [validationMixin],
  data () {
    return {
      form: {
        email: null,
        password: null,
        submitted: false
      }
    }
  },
  computed: {
    ...mapState({ alert: (state) => state.alert }),
    ...mapState('auth', ['status'])
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    validateState (name) {
      const { $dirty, $anyError } = this.$v.form[name]
      if ($dirty) {
        return { 'is-danger': $anyError, 'is-link': !$anyError }
      } else {
        return { 'is-danger': false, 'is-link': true }
      }
    },
    // 3. Handle Submit - Call Store fundtion.
    onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        this.isLoading = false
        return
      }
      const auth = this.form
      this.login(auth)
    }
  }
}
</script>
